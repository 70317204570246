import { mapState, mapActions } from 'vuex'
import router from '../../../plugins/router'
import SContainer from '../../../components/ui/s-container'
import SButton from '../../../components/ui/s-button'
import STitle from '../../../components/ui/s-title'
import iconBack from '../../../assets/images/issues/back_eco_icon.svg'

import iconLocker from '../../../assets/images/issues/locker_eco_icon.svg'
import iconDoubleCheck from '../../../assets/images/issues/double_check_icon.svg'
import iconSend from '../../../assets/images/issues/send_eco_icon.svg'
import iconHappy from '../../../assets/images/issues/happy_white_icon.svg'
import { Auth } from '../../../plugins/firebase'

export default {
  name: 'Conversation',
  components: {
    Feedback: () => import('../../../components/IssueFeedback'),
    SContainer,
    SButton,
    STitle,
  },

  data() {
    return {
      activities: [],
      adminReplied: null,
      iconBack,
      iconDoubleCheck,
      iconHappy,
      iconLocker,
      iconSend,
      issueId: this.$route.params?.issueId,
      message: '',
      showSnackMsg: false,
      signedFiles: [],
      slug: this.$route.params?.slug,
      status: '',
      issueFollowUps: [],
    }
  },

  computed: {
    ...mapState(['issue', 'issueReplied', 'loading', 'error', 'notice']),

    answered() {
      return (
        this.issue?.status === 'ANSWERED' || !!this.issue?.completed || false
      )
    },

    backTo() {
      const isLoggedIn = !Auth.currentUser?.isAnonymous

      const loggedInRoute = isLoggedIn
        ? { name: 'my-issues' }
        : { name: 'welcome' }

      const companyIssuesRoute = { name: 'issues', params: { slug: this.slug } }

      return this.slug ? companyIssuesRoute : loggedInRoute
    },
  },

  updated() {
    if (this.$refs.chat) {
      const _height = this.$refs.chat.$el?.clientHeight
      window.scrollTo({ top: _height, behavior: 'smooth' })
    }
  },

  async created() {
    if (!this.issueId) {
      router.push({ name: 'my-issues' })
    }

    const issue = await this.getIssue(this.issueId)
    this.status = issue.status
    this.adminReplied = issue.replied

    await this.getIssuesFiles(issue.files)

    this.issueFollowUps = await this.getIssueFollowUpMessages(this.issueId)
  },

  methods: {
    ...mapActions([
      'getIssue',
      'sendIssueFollowUpMessage',
      'getIssueFollowUpMessages',
      'getS3FileUrl',
    ]),

    async submit() {
      const message = await this.sendIssueFollowUpMessage({
        message: this.message,
      })
      this.issueFollowUps.push(message)

      window.scrollTo({
        top: document.body.scrollHeight + 200,
        behavior: 'smooth',
      })

      this.message = ''
      this.adminReplied = false
      this.showSnackMsg = false
    },

    setSuccess() {
      this.status = 'SOLVED'
      this.adminReplied = true
    },

    setConfuse() {
      this.status = 'ANSWERED'
      this.adminReplied = false
      this.showSnackMsg = true
    },

    async getFileURL(key) {
      return this.getS3FileUrl(key)
    },

    async getIssuesFiles(files = []) {
      this.signedFiles = files.map((key) => ({ key }))

      const promises = this.signedFiles.map(async (item) => {
        const [filename, extension] = item.key.split('.')
        item.name = filename
        item.ext = extension.toUpperCase()
        const data = await this.getFileURL(item.key)
        item.url = data?.url
        await this.$forceUpdate()
      })

      return Promise.all(promises)
    },
  },

  watch: {
    issueReplied() {
      this.adminReplied = this.issueReplied
    },
  },
}
